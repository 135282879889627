import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chat as StreamChatComponent } from 'stream-chat-react';

import '@stream-io/stream-chat-css/dist/css/index.css';

import { useAppChat } from '../../contexts/appChat';
import { useAppDispatch } from '../../hooks';
import { getProfile } from '../../reducers/user';
import { hasAuthToken } from '../../services/auth';
import { DashboardLayout } from '../../shared';

import {
    ChatList,
    ChatContainer as ChatMessageContainer,
    CreateGroup,
    CreateOneOnOne
} from './components';
import useWindowDimensions from './hooks/useWindowDimensions';
import { ChatContainer, PageContent } from './styles';

type Props = Record<string, never>;

type NavigationState = {
    channelId?: string;
};

const Chat: FunctionComponent<Props> = ({}: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const channelId = (locationState as NavigationState)?.channelId;
    const { height } = useWindowDimensions();

    const [showCreateGroupPopup, setShowCreateGroupPopup] =
        useState<boolean>(false);
    const [showCreateMemberPopup, setShowCreateMemberPopup] =
        useState<boolean>(false);

    const { chatClient } = useAppChat();

    useEffect(() => {
        if (!hasAuthToken()) {
            navigate('/');
        }

        dispatch(getProfile({}));
    }, [navigate, dispatch]);

    return (
        <DashboardLayout hideHeader>
            {chatClient && (
                <StreamChatComponent client={chatClient}>
                    <PageContent>
                        <ChatContainer height={height}>
                            <ChatList
                                createGroupClick={() =>
                                    setShowCreateGroupPopup(true)
                                }
                                createMemberClick={() =>
                                    setShowCreateMemberPopup(true)
                                }
                            />
                            <ChatMessageContainer
                                initialChannelId={channelId}
                            />
                        </ChatContainer>
                    </PageContent>

                    {showCreateGroupPopup && (
                        <CreateGroup
                            client={chatClient}
                            onClose={() => setShowCreateGroupPopup(false)}
                        />
                    )}
                    {showCreateMemberPopup && (
                        <CreateOneOnOne
                            client={chatClient}
                            onClose={() => setShowCreateMemberPopup(false)}
                        />
                    )}
                </StreamChatComponent>
            )}
        </DashboardLayout>
    );
};

export default Chat;
