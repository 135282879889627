import * as React from 'react';

interface Props {
    fill?: string;
}

const Notes = ({ fill = 'red' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill={fill}
        className="tab_svg"
    >
        <path d="M3.6 10.8H5.805L11.115 5.535C11.205 5.445 11.25 5.3325 11.25 5.1975C11.25 5.0625 11.205 4.95 11.115 4.86L9.54 3.285C9.45 3.195 9.3375 3.15 9.2025 3.15C9.0675 3.15 8.955 3.195 8.865 3.285L3.6 8.595V10.8ZM7.65 10.8H14.4V9H9.45L7.65 10.8ZM0 18V1.8C0 1.305 0.17625 0.88125 0.52875 0.52875C0.88125 0.17625 1.305 0 1.8 0H16.2C16.695 0 17.1188 0.17625 17.4713 0.52875C17.8238 0.88125 18 1.305 18 1.8V12.6C18 13.095 17.8238 13.5188 17.4713 13.8713C17.1188 14.2238 16.695 14.4 16.2 14.4H3.6L0 18ZM2.835 12.6H16.2V1.8H1.8V13.6125L2.835 12.6Z" />
    </svg>
);
export default Notes;
