import {
    $createCodeNode,
    $isCodeNode,
    getCodeLanguages,
    getDefaultCodeLanguage
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createHeadingNode,
    $createQuoteNode,
    $isHeadingNode
} from '@lexical/rich-text';
import { $isAtNodeEnd, $setBlocksType } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getSelection,
    $isRangeSelection,
    BaseSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_HIGH,
    CommandListenerPriority,
    // FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INSERT_PARAGRAPH_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_ENTER_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { createPortal } from 'react-dom';

const LowPriority: CommandListenerPriority = 1;

const supportedBlockTypes: Set<string> = new Set([
    'paragraph',
    'quote',
    'code',
    'h1',
    'h2',
    'ul',
    'ol'
]);

const blockTypeToBlockName: { [key: string]: string } = {
    code: 'Code Block',
    h1: 'Large Heading',
    h2: 'Small Heading',
    h3: 'Heading',
    h4: 'Heading',
    h5: 'Heading',
    ol: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
    ul: 'Bulleted List'
};

function Divider(): JSX.Element {
    return <div className="divider" />;
}

function positionEditorElement(editor: any, rect: any): void {
    if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
    } else {
        editor.style.opacity = '1';
        editor.style.top = `${
            rect.top + rect.height + window.pageYOffset + 10
        }px`;
        editor.style.left = `${
            rect.left +
            window.pageXOffset -
            editor.offsetWidth / 2 +
            rect.width / 2
        }px`;
    }
}

function FloatingLinkEditor({ editor }: { editor: any }): JSX.Element {
    const editorRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState<BaseSelection | null>(
        null
    );

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl('');
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection?.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection?.anchorNode)
        ) {
            const domRange = nativeSelection?.getRangeAt(0);
            let rect;
            if (nativeSelection?.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange?.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (
            !activeElement ||
            activeElement.className !== 'editor-link-input'
        ) {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl('');
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(
                ({ editorState }: { editorState: any }) => {
                    editorState.read(() => {
                        updateLinkEditor();
                    });
                }
            ),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    return (
        <div ref={editorRef} className="link-editor">
            {isEditMode ? (
                <input
                    ref={inputRef}
                    className="editor-link-input"
                    value={linkUrl}
                    onChange={(event) => {
                        setLinkUrl(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            if (lastSelection !== null) {
                                if (linkUrl !== '') {
                                    editor.dispatchCommand(
                                        TOGGLE_LINK_COMMAND,
                                        linkUrl
                                    );
                                }
                                setEditMode(false);
                            }
                        } else if (event.key === 'Escape') {
                            event.preventDefault();
                            setEditMode(false);
                        }
                    }}
                />
            ) : (
                <>
                    <div className="editor-link-input">
                        <a
                            href={linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {linkUrl}
                        </a>
                        <div
                            className="link-edit"
                            role="button"
                            tabIndex={0}
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

function Select({
    onChange,
    className,
    options,
    value
}: {
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    className: string;
    options: string[];
    value: string;
}): JSX.Element {
    return (
        <select className={className} onChange={onChange} value={value}>
            <option hidden={true} value="" />
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

function getSelectedNode(selection: any): any {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

function BlockOptionsDropdownList({
    editor,
    blockType,
    toolbarRef,
    setShowBlockOptionsDropDown
}: {
    editor: any;
    blockType: string;
    toolbarRef: any;
    setShowBlockOptionsDropDown: (show: boolean) => void;
}): JSX.Element {
    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const { top, left } = toolbar.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;
        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle = (event: MouseEvent) => {
                const target = event.target as HTMLElement;

                if (!dropDown.contains(target) && !toolbar.contains(target)) {
                    setShowBlockOptionsDropDown(false);
                }
            };
            document.addEventListener('click', handle);

            return () => {
                document.removeEventListener('click', handle);
            };
        }
    }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

    const formatParagraph = () => {
        if (blockType !== 'paragraph') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createParagraphNode());
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatLargeHeading = () => {
        if (blockType !== 'h1') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode('h1'));
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatSmallHeading = () => {
        if (blockType !== 'h2') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode('h2'));
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatBulletList = () => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatNumberedList = () => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatQuote = () => {
        if (blockType !== 'quote') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    const formatCode = () => {
        if (blockType !== 'code') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createCodeNode());
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    };

    return (
        <div className="editor-dropdown" ref={dropDownRef}>
            <button className="editor-item" onClick={formatParagraph}>
                <span className="editor-icon editor-paragraph" />
                <span className="editor-text">Normal</span>
                {blockType === 'paragraph' && (
                    <span className="editor-active" />
                )}
            </button>
            <button className="editor-item" onClick={formatLargeHeading}>
                <span className="editor-icon editor-large-heading" />
                <span className="editor-text">Large Heading</span>
                {blockType === 'h1' && <span className="editor-active" />}
            </button>
            <button className="editor-item" onClick={formatSmallHeading}>
                <span className="editor-icon editor-small-heading" />
                <span className="editor-text">Small Heading</span>
                {blockType === 'h2' && <span className="editor-active" />}
            </button>
            <button className="editor-item" onClick={formatBulletList}>
                <span className="editor-icon editor-bullet-list" />
                <span className="editor-text">Bullet List</span>
                {blockType === 'ul' && <span className="editor-active" />}
            </button>
            <button className="editor-item" onClick={formatNumberedList}>
                <span className="editor-icon editor-numbered-list" />
                <span className="editor-text">Numbered List</span>
                {blockType === 'ol' && <span className="editor-active" />}
            </button>
            <button className="editor-item" onClick={formatQuote}>
                <span className="editor-icon editor-quote" />
                <span className="editor-text">Quote</span>
                {blockType === 'quote' && <span className="editor-active" />}
            </button>
            <button className="editor-item" onClick={formatCode}>
                <span className="editor-icon editor-code-language" />
                <span className="editor-text">Code Block</span>
                {blockType === 'code' && <span className="editor-active" />}
            </button>
        </div>
    );
}

export default function ToolbarPlugin({
    onSubmit
}: {
    onSubmit: () => void;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState('paragraph');
    const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
        null
    );
    const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
        useState(false);
    const [codeLanguage, setCodeLanguage] = useState('');
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);

    useEffect(() => {
        return editor.registerCommand(
            KEY_ENTER_COMMAND,
            (event: KeyboardEvent) => {
                const { shiftKey, key } = event;
                if (blockType === 'ul' || blockType === 'ol') {
                    return true;
                }

                if (key === 'Enter' && !shiftKey) {
                    event.preventDefault();

                    onSubmit();

                    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                } else if (key === 'Enter' && shiftKey) {
                    editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
                    setTimeout(() => {
                        editor.dispatchCommand(KEY_BACKSPACE_COMMAND, event);
                    }, 10);
                }

                return true;
            },
            COMMAND_PRIORITY_HIGH
        );
    }, [blockType, editor, onSubmit]);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(
                        anchorNode,
                        ListNode
                    );
                    const type = parentList
                        ? parentList.getTag()
                        : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                    if ($isCodeNode(element)) {
                        setCodeLanguage(
                            element.getLanguage() || getDefaultCodeLanguage()
                        );
                    }
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsCode(selection.hasFormat('code'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(
                ({ editorState }: { editorState: any }) => {
                    editorState.read(() => {
                        updateToolbar();
                    });
                }
            ),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload: any) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload: any) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload: any) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const codeLanguges = useMemo(() => getCodeLanguages(), []);
    const onCodeLanguageSelect = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            editor.update(() => {
                if (selectedElementKey !== null) {
                    const node = $getNodeByKey(selectedElementKey);
                    if ($isCodeNode(node)) {
                        node.setLanguage(e.target.value);
                    }
                }
            });
        },
        [editor, selectedElementKey]
    );

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    return (
        <div>
            <div className="editor-toolbar" ref={toolbarRef}>
                <button
                    disabled={!canUndo}
                    onClick={() => {
                        editor.dispatchCommand(UNDO_COMMAND, undefined as void);
                    }}
                    className="editor-toolbar-item editor-spaced"
                    aria-label="Undo"
                >
                    <i className="editor-format editor-undo" />
                </button>
                <button
                    disabled={!canRedo}
                    onClick={() => {
                        editor.dispatchCommand(REDO_COMMAND, undefined as void);
                    }}
                    className="editor-toolbar-item"
                    aria-label="Redo"
                >
                    <i className="editor-format editor-redo" />
                </button>
                <Divider />
                {supportedBlockTypes.has(blockType) && (
                    <>
                        <button
                            className="editor-toolbar-item editor-block-controls"
                            onClick={() =>
                                setShowBlockOptionsDropDown(
                                    !showBlockOptionsDropDown
                                )
                            }
                            aria-label="Formatting Options"
                        >
                            <span
                                className={
                                    'editor-icon editor-block-type ' + blockType
                                }
                            />
                            <span className="editor-text">
                                {blockTypeToBlockName[blockType]}
                            </span>
                            <i className="editor-chevron-down" />
                        </button>
                        {showBlockOptionsDropDown &&
                            createPortal(
                                <BlockOptionsDropdownList
                                    editor={editor}
                                    blockType={blockType}
                                    toolbarRef={toolbarRef}
                                    setShowBlockOptionsDropDown={
                                        setShowBlockOptionsDropDown
                                    }
                                />,
                                document.body
                            )}
                        <Divider />
                    </>
                )}
                {blockType === 'code' ? (
                    <>
                        <Select
                            className="editor-toolbar-item editor-code-language"
                            onChange={onCodeLanguageSelect}
                            options={codeLanguges}
                            value={codeLanguage}
                        />
                        <i className="editor-chevron-down editor-inside" />
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'bold'
                                );
                            }}
                            className={
                                'editor-toolbar-item editor-spaced ' +
                                (isBold ? 'editor-active' : '')
                            }
                            aria-label="Format Bold"
                        >
                            <i className="editor-format editor-bold" />
                        </button>
                        <button
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'italic'
                                );
                            }}
                            className={
                                'editor-toolbar-item editor-spaced ' +
                                (isItalic ? 'editor-active' : '')
                            }
                            aria-label="Format Italics"
                        >
                            <i className="editor-format editor-italic" />
                        </button>
                        <button
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'underline'
                                );
                            }}
                            className={
                                'editor-toolbar-item editor-spaced ' +
                                (isUnderline ? 'editor-active' : '')
                            }
                            aria-label="Format Underline"
                        >
                            <i className="editor-format editor-underline" />
                        </button>
                    </>
                )}
            </div>
            {blockType !== 'code' && (
                <div className="editor-toolbar">
                    <button
                        onClick={() => {
                            editor.dispatchCommand(
                                FORMAT_TEXT_COMMAND,
                                'strikethrough'
                            );
                        }}
                        className={
                            'editor-toolbar-item editor-spaced ' +
                            (isStrikethrough ? 'editor-active' : '')
                        }
                        aria-label="Format Strikethrough"
                    >
                        <i className="editor-format editor-strikethrough" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
                        }}
                        className={
                            'editor-toolbar-item editor-spaced ' +
                            (isCode ? 'editor-active' : '')
                        }
                        aria-label="Insert Code"
                    >
                        <i className="editor-format editor-code-icon" />
                    </button>
                    <button
                        onClick={insertLink}
                        className={
                            'editor-toolbar-item editor-spaced ' +
                            (isLink ? 'editor-active' : '')
                        }
                        aria-label="Insert Link"
                    >
                        <i className="editor-format editor-link" />
                    </button>
                    {isLink &&
                        createPortal(
                            <FloatingLinkEditor editor={editor} />,
                            document.body
                        )}
                    <Divider />
                    {/*
                    <button
                        onClick={() => {
                            editor.dispatchCommand(
                                FORMAT_ELEMENT_COMMAND,
                                'left'
                            );
                        }}
                        className="editor-toolbar-item editor-spaced"
                        aria-label="Left Align"
                    >
                        <i className="editor-format editor-left-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(
                                FORMAT_ELEMENT_COMMAND,
                                'center'
                            );
                        }}
                        className="editor-toolbar-item editor-spaced"
                        aria-label="Center Align"
                    >
                        <i className="editor-format editor-center-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(
                                FORMAT_ELEMENT_COMMAND,
                                'right'
                            );
                        }}
                        className="editor-toolbar-item editor-spaced"
                        aria-label="Right Align"
                    >
                        <i className="editor-format editor-right-align" />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(
                                FORMAT_ELEMENT_COMMAND,
                                'justify'
                            );
                        }}
                        className="editor-toolbar-item"
                        aria-label="Justify Align"
                    >
                        <i className="editor-format editor-justify-align" />
                    </button>
                    */}
                </div>
            )}
        </div>
    );
}
