import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;

    .str-chat-channel-list {
        background-color: transparent;
        position: inherit;
    }

    .str-chat__channel-list-messenger {
        background: transparent;
    }

    .str-chat__channel-list-messenger__main {
        padding: 0;
    }

    .str-chat__channel-list-messenger {
        min-width: unset;
    }

    @media only screen and (min-width: 1300px) {
        min-width: 15%;
    }
`;

export const ListContainer = styled.div`
    margin-top: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
