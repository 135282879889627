import {
    $convertFromMarkdownString,
    $convertToMarkdownString,
    TRANSFORMERS
} from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useEffect, useState } from 'react';

interface Props {
    initialMarkdown?: string;
    onMarkDownChanged: (markdown: string) => void;
}

const MarkDownPlugin = ({ initialMarkdown, onMarkDownChanged }: Props) => {
    const [editor] = useLexicalComposerContext();

    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (!initialMarkdown || !isFirstRender) return;

        setIsFirstRender(false);

        editor.update(() => {
            $convertFromMarkdownString(initialMarkdown, TRANSFORMERS);
        });
    }, [editor, initialMarkdown, isFirstRender]);

    const markDownUnderlineFix = (markdown: string): string => {
        const markDownArray = markdown.split('');
        let newMarkDown = '';
        let arrowCount = 0;
        if (markDownArray.length > 1) {
            for (let index = 0; index < markDownArray.length; index++) {
                if (markDownArray[index] === '<') {
                    arrowCount++;
                    if (arrowCount % 2 === 0) {
                        newMarkDown = newMarkDown + '</';
                    } else {
                        newMarkDown = newMarkDown + markDownArray[index];
                    }
                } else {
                    newMarkDown = newMarkDown + markDownArray[index];
                }
            }
        } else {
            newMarkDown = markdown;
        }
        return newMarkDown;
    };

    return (
        <OnChangePlugin
            onChange={(editorState) => {
                editorState.read(() => {
                    const markdown = $convertToMarkdownString(TRANSFORMERS);
                    const newMarkDown = markDownUnderlineFix(markdown);
                    onMarkDownChanged(newMarkDown);
                });
            }}
        />
    );
};

export default MarkDownPlugin;
