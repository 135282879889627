import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';

import Activity from 'src/assets/activity.svg';
import Drop from 'src/assets/drop.svg';
import Food from 'src/assets/food.svg';
import Hydration from 'src/assets/hydrationDrop.svg';
import Insulin from 'src/assets/insulin.svg';
import Medication from 'src/assets/medication.svg';
import Timer from 'src/assets/timer.svg';
import Weight from 'src/assets/weight.svg';
import { Log } from 'src/types/log';

export const logTypeDetails: Record<string, Log> = {
    UserExercise: {
        icon: Activity,
        title: 'Activity'
    },
    UserWeight: {
        icon: Weight,
        title: 'Weight'
    },
    UserFast: {
        icon: Timer,
        title: 'Begin fast'
    },
    UserInsulin: {
        icon: Insulin,
        title: 'Insulin'
    },
    UserGlucose: {
        icon: Drop,
        title: 'Glucose'
    },
    UserDrink: {
        icon: Hydration,
        title: 'Hydration'
    },
    UserMedication: {
        icon: Medication,
        title: 'Medication'
    },
    UserFood: {
        icon: Food,
        title: 'Food'
    },
    UserLesson: {
        icon: Activity,
        title: 'Lesson Completed'
    }
};

export const NOTIFICATION_TYPE = {
    STREAM_CHAT_MESSAGE: 'message.new',
    LESSON_COMPLETED: 'lesson.completed'
};

export const CLIENTS_TABLE_COLUMNS = [
    'name',
    'type',
    'engagement',
    'fbg',
    'last_weight',
    'last_week_avg_fbg',
    'activity_type',
    'duration',
    'weekly_total',
    'hydration'
];

export const timeFrames = [
    { label: 'Last 7 days', value: '7' },
    { label: 'Last 14 days', value: '14' },
    { label: 'Last 30 days', value: '30' }
];

export const initialLexicalConfig = {
    namespace: 'MyEditor',
    theme: {
        ltr: 'lexical-editor-ltr',
        rtl: 'lexical-editor-rtl',
        placeholder: 'lexical-editor-placeholder',
        paragraph: 'lexical-editor-paragraph',
        quote: 'lexical-editor-quote',
        heading: {
            h1: 'lexical-editor-heading-h1',
            h2: 'lexical-editor-heading-h2',
            h3: 'lexical-editor-heading-h3',
            h4: 'lexical-editor-heading-h4',
            h5: 'lexical-editor-heading-h5'
        },
        list: {
            nested: {
                listitem: 'editor-nested-listitem'
            },
            ol: 'editor-list-ol',
            ul: 'editor-list-ul',
            listitem: 'editor-listitem'
        },
        image: 'editor-image',
        link: 'editor-link',
        text: {
            bold: 'editor-text-bold',
            italic: 'editor-text-italic',
            overflowed: 'editor-text-overflowed',
            hashtag: 'editor-text-hashtag',
            underline: 'editor-text-underline',
            strikethrough: 'editor-text-strikethrough',
            underlineStrikethrough: 'editor-text-underlineStrikethrough',
            code: 'editor-text-code'
        },
        code: 'editor-code',
        codeHighlight: {
            atrule: 'editor-tokenAttr',
            attr: 'editor-tokenAttr',
            boolean: 'editor-tokenProperty',
            builtin: 'editor-tokenSelector',
            cdata: 'editor-tokenComment',
            char: 'editor-tokenSelector',
            class: 'editor-tokenFunction',
            'class-name': 'editor-tokenFunction',
            comment: 'editor-tokenComment',
            constant: 'editor-tokenProperty',
            deleted: 'editor-tokenProperty',
            doctype: 'editor-tokenComment',
            entity: 'editor-tokenOperator',
            function: 'editor-tokenFunction',
            important: 'editor-tokenVariable',
            inserted: 'editor-tokenSelector',
            keyword: 'editor-tokenAttr',
            namespace: 'editor-tokenVariable',
            number: 'editor-tokenProperty',
            operator: 'editor-tokenOperator',
            prolog: 'editor-tokenComment',
            property: 'editor-tokenProperty',
            punctuation: 'editor-tokenPunctuation',
            regex: 'editor-tokenVariable',
            selector: 'editor-tokenSelector',
            string: 'editor-tokenSelector',
            symbol: 'editor-tokenProperty',
            tag: 'editor-tokenProperty',
            url: 'editor-tokenOperator',
            variable: 'editor-tokenVariable'
        }
    },
    onError: (error: any) => {
        console.error(error);
    },
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
    ]
};
