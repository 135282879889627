import * as React from 'react';

interface Props {
    fill?: string;
}

const Assessment = ({ fill = 'red' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill={fill}
        className="tab_svg"
    >
        <path d="M3.73333 13.0667H5.6V8.4H3.73333V13.0667ZM11.2 13.0667H13.0667V3.73333H11.2V13.0667ZM7.46667 13.0667H9.33333V10.2667H7.46667V13.0667ZM7.46667 8.4H9.33333V6.53333H7.46667V8.4ZM1.86667 16.8C1.35333 16.8 0.913889 16.6172 0.548333 16.2517C0.182778 15.8861 0 15.4467 0 14.9333V1.86667C0 1.35333 0.182778 0.913889 0.548333 0.548333C0.913889 0.182778 1.35333 0 1.86667 0H14.9333C15.4467 0 15.8861 0.182778 16.2517 0.548333C16.6172 0.913889 16.8 1.35333 16.8 1.86667V14.9333C16.8 15.4467 16.6172 15.8861 16.2517 16.2517C15.8861 16.6172 15.4467 16.8 14.9333 16.8H1.86667ZM1.86667 14.9333H14.9333V1.86667H1.86667V14.9333Z" />
    </svg>
);
export default Assessment;
