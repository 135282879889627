import { ELEMENT_TRANSFORMERS, TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/hooks';
import {
    ClientSelectors,
    addNote,
    deleteNote,
    editNote,
    getNotes
} from 'src/reducers/client';
import { Size } from 'src/shared/text';
import { Note } from 'src/types/note';
import { Colors } from 'src/utils/colors';
import { initialLexicalConfig } from 'src/utils/constants';

import NoteContainer from './noteContainer';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkDownPlugin from './plugins/MarkDownPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import {
    AddExtraSpace,
    LastNotesText,
    NoteViewContainer,
    NoteWrapper,
    RootContainer,
    currentNoteStyle,
    previousNoteStyle
} from './styles';
import './plugins/lexical.css';

interface Props {
    setEdit: (edit: boolean) => void;
    edit: boolean;
}

const NoteSection: FunctionComponent<Props> = ({ setEdit, edit }: Props) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { notes } = ClientSelectors();

    const { list, next_page_number, has_next } = notes[id as string] || {};

    const [message, setMessage] = useState<string>('');
    const [editedId, setEditedId] = useState<number | null>(null);
    const [messageDate, setMessageDate] = useState<Date | undefined>(undefined);
    const [note, setNote] = useState<Note | undefined>(undefined);

    useEffect(() => {
        dispatch(
            getNotes({
                page: 1,
                limit: 10,
                clientId: id as string
            })
        );
    }, [dispatch, id]);

    const loadMoreData = useCallback(() => {
        dispatch(
            getNotes({
                page: next_page_number as number,
                limit: 10,
                clientId: id as string
            })
        )
            .unwrap()
            .catch((err) => {
                alert(err.message);
            });
    }, [dispatch, id, next_page_number]);

    const handleKeyDown = useCallback(() => {
        let newNote = {
            id: editedId === null ? Math.floor(Math.random() * 1000) : editedId,
            client_id: id ?? '',
            content: message,
            created_at: messageDate || new Date()
        };
        if (editedId !== null) {
            dispatch(editNote(newNote));
        } else {
            dispatch(addNote(newNote));
        }
        setEdit(false);
        setEditedId(null);
        setMessage('');
    }, [dispatch, message, id, messageDate, setEdit, editedId]);

    useEffect(() => {
        if (edit) {
            if (editedId) {
                setEdit(false);
                return;
            }
            setMessageDate(undefined);
            setNote(undefined);
        }
    }, [edit, editedId, setEdit]);

    const notes_client = useMemo(() => {
        if (id && notes[id]) {
            return [...notes[id].list].sort((first, second) => {
                return second.created_at > first.created_at ? 1 : -1;
            });
        }
        return [];
    }, [id, notes]);

    const ondeleteNote = useCallback(
        (noteId: number) => {
            dispatch(
                deleteNote({
                    id: noteId,
                    clientId: id ?? ''
                })
            );
        },
        [id, dispatch]
    );

    const getTransformar = () => {
        TRANSFORMERS.push({
            format: ['underline'],
            tag: '<u>',
            intraword: true,
            type: 'text-format'
        });
        return [...TRANSFORMERS, ...ELEMENT_TRANSFORMERS];
    };

    return (
        <RootContainer>
            {(edit && !editedId) ||
            (editedId !== null && editedId === note?.id) ? (
                <LexicalComposer initialConfig={initialLexicalConfig}>
                    <NoteViewContainer>
                        <ToolbarPlugin onSubmit={handleKeyDown} />
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable className="editor-input" />
                            }
                            placeholder={<div />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <HistoryPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin
                            transformers={getTransformar()}
                        />
                        <MarkDownPlugin
                            onMarkDownChanged={(markdown) =>
                                setMessage(markdown)
                            }
                        />
                        <ClearEditorPlugin />
                    </NoteViewContainer>
                </LexicalComposer>
            ) : (
                note?.content && (
                    <NoteContainer
                        id={note.id}
                        style={currentNoteStyle}
                        date={note.created_at}
                        onDoubleClick={(children, date) => {
                            setMessageDate(date);
                            setEditedId(note.id);
                        }}
                        onDeleteNote={ondeleteNote}
                    >
                        {note.content}
                    </NoteContainer>
                )
            )}
            <LastNotesText
                fontFamily="Poppins"
                fontWeight="500"
                fontSize={Size.X3Small}
                color={Colors.extra.sub_title_text}
            >
                Last Comments:
            </LastNotesText>
            <InfiniteScroll
                dataLength={list?.length || 10}
                next={loadMoreData}
                hasMore={has_next}
                scrollableTarget="notesDiv"
                loader={<h4>Loading...</h4>}
                data-testid="infinite-scroll"
            >
                {notes_client.map((noteClient) =>
                    !edit &&
                    editedId !== null &&
                    editedId === noteClient?.id ? (
                        <NoteWrapper>
                            <LexicalComposer
                                initialConfig={initialLexicalConfig}
                            >
                                <NoteViewContainer>
                                    <ToolbarPlugin onSubmit={handleKeyDown} />
                                    <RichTextPlugin
                                        contentEditable={
                                            <ContentEditable className="editor-input" />
                                        }
                                        placeholder={<div />}
                                        ErrorBoundary={LexicalErrorBoundary}
                                    />
                                    <AutoFocusPlugin />
                                    <CodeHighlightPlugin />
                                    <ListPlugin />
                                    <LinkPlugin />
                                    <AutoLinkPlugin />
                                    <HistoryPlugin />
                                    <ListMaxIndentLevelPlugin maxDepth={7} />
                                    <MarkdownShortcutPlugin
                                        transformers={TRANSFORMERS}
                                    />
                                    <MarkDownPlugin
                                        onMarkDownChanged={(markdown) =>
                                            setMessage(markdown)
                                        }
                                        initialMarkdown={message}
                                    />
                                    <ClearEditorPlugin />
                                </NoteViewContainer>
                            </LexicalComposer>
                        </NoteWrapper>
                    ) : (
                        <NoteContainer
                            id={noteClient.id}
                            key={noteClient.id}
                            style={previousNoteStyle}
                            date={noteClient.created_at}
                            onDoubleClick={(children, date) => {
                                setMessage(children);
                                setMessageDate(date);
                                setEditedId(noteClient.id);
                            }}
                            onDeleteNote={ondeleteNote}
                        >
                            {noteClient.content}
                        </NoteContainer>
                    )
                )}
                <AddExtraSpace />
            </InfiniteScroll>
        </RootContainer>
    );
};

export default NoteSection;
