import MarkdownPreview from '@uiw/react-markdown-preview';
import moment from 'moment';
import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';

import DeleteSvg from 'src/assets/delete.svg';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/colors';

import {
    CommentMeta,
    DateText,
    NoteWrapper,
    StyledButton,
    TextWrapper
} from './styles';

interface Props {
    id: number;
    children: string;
    date: Date;
    style: React.CSSProperties;
    onDoubleClick: (children: string, date: Date) => void;
    onDeleteNote: (id: number) => void;
}

export const MarkDownContainer = styled(MarkdownPreview)`
    color: ${Colors.extra.black};
    background-color: ${Colors.extra.transparent};
    h1,
    h2 {
        border-bottom: unset;
    }
    pre {
        background-color: unset;
    }
`;

const NoteContainer: FunctionComponent<Props> = ({
    id,
    children,
    date,
    style,
    onDoubleClick,
    onDeleteNote
}: Props) => {
    const editNote = useCallback(
        (e: React.MouseEvent) => {
            if (e.detail === 2) {
                // need to remove underline when we edit markdown because markdown is not support underline and we use HTML tag.
                const markdown = children
                    .split('<u>')
                    .join('')
                    .split('</u>')
                    .join('');
                onDoubleClick(markdown, date);
            }
        },
        [children, date, onDoubleClick]
    );
    return (
        <NoteWrapper>
            <TextWrapper style={style} onClick={editNote}>
                <MarkDownContainer source={children} />
            </TextWrapper>
            <CommentMeta>
                <DateText
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize={Size.X3Small}
                    color={Colors.extra.sub_title_text}
                >
                    {moment(date).format('MMM D, YYYY [at] h:mm:ss A')}
                </DateText>
                <StyledButton
                    width="30px"
                    height="30px"
                    padding="0px"
                    borderRadius="8px"
                    onClick={() => onDeleteNote(id)}
                >
                    <img src={DeleteSvg} alt="delete" />
                </StyledButton>
            </CommentMeta>
        </NoteWrapper>
    );
};

export default NoteContainer;
